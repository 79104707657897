import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import SubscriptionPlanService from '@/services/subscription-plan.service'
import EventBus from '@/util/EventBus'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import moment from 'moment'
import browserCache from '@/services/browser-cache'
import getSymbolFromCurrency from 'currency-symbol-map'
import PaymentService from '@/services/payment.service'
import { Payment } from '@/models/payments/Payment'
import StopSubscription from '@/components/vue-chat/lib/StopSubscription/StopSubscription.vue'

export default {
  name: 'SubscriptionPlans',
  components: {
    StopSubscription,
    HeaderTopDashboard,
  },
  data () {
    return {
      currencySymbol: getSymbolFromCurrency(browserCache.getJson('user').currencyCode),
      plans: [],
      userSubscriptions: [],
      Subscriptions: Subscriptions,
      payment: new Payment(),
      stopSubscriptionModalData: {
        show: false,
        msisdn: '0000000000',
        finalizedDate: Date,
        userPlanId: 0,
        userSubscriptionId: 0,
        hasSubscription: true,
      },
      user: this.$store.getters['auth/getUser'] || JSON.parse(localStorage.getItem('user')),
    }
  },
  created () {
    Subscriptions.checkSubscription(
      Subscriptions.WHATSAPP,
      () => {},
      () => {},
    )
    this.getPaymentMethods()
    this.getSubscriptionPlans()
  },
  computed: {
    hasValidBillingProfile () {
      return this.payment?.billingProfiles ?? false
    },
    hasActiveSubscriptions () {
      return this.userSubscriptions.reduce((prev, curr) => {
        return prev || moment().diff(curr.endDate, 'minutes') < 0
      }, false)
    },
    hasPrimaryWhatsappSubscription () {
      return this.userSubscriptions.filter(s => {
        return s.planType === Subscriptions.WHATSAPP && s.isAdditional === 0
      }).length > 0
    },
  },
  methods: {
    isSubscriptionPlanWhatsapp (sub) {
      return sub.planType === this.Subscriptions.WHATSAPP
    },
    hasCard (sub) {
      return sub.subscription?.card
    },
    getPaymentMethods () {
      this.payment.billingProfiles = true
      PaymentService.getPaymentMethods()
        .then(
          (response) => {
            this.payment.activeCreditCard = response.activeCreditCard
            this.payment.activeMultibanco = response.activeMultibanco
            this.payment.activeBoleto = response.activeBoleto
            this.payment.activePaypal = response.activePaypal
            this.payment.activePlan = response.activePlan
            this.payment.activeSubscription = response.activeSubscription
            this.payment.activeTransfer = response.activeTransfer
            this.payment.billingProfiles = response.billingProfiles
            this.payment.currency = response.currency
            this.payment.fromPlans = response.fromPlans
            this.payment.paymentsInfo = response.paymentsInfo
            this.payment.show = true
          },
          () => {},
        )
        .finally(() => {})
    },
    hasActiveSubscription (planType) {
      return this.userSubscriptions
        .filter(s => s.planType === planType)
        .reduce((prev, curr) => prev || moment().diff(curr.endDate, 'minutes') < 0, false)
    },
    isPlanWhatsapp (plan) {
      return plan.name === Subscriptions.WHATSAPP
    },
    needsConfig (plan) {
      switch (plan.planType) {
        case 'whatsapp':
          return !plan.route
        default:
          return false
      }
    },
    configure (plan) {
      switch (plan.planType) {
        case 'whatsapp':
          this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
          break
        default:
          break
      }
    },
    isPlanSubscribed (plan) {
      return JSON.parse(localStorage.getItem('user'))?.subscriptions[plan]
    },
    getSubscriptionPlans () {
      EventBus.$emit('showLoading', true)
      SubscriptionPlanService.getSubscriptionPlans()
      .then(
        plans => {
          // console.log('plans', plans)
          this.plans = [
            ...plans,
            ...this.plans,
          ]
          SubscriptionPlanService.getSubscribedPlans()
          .then(
            subscriptions => {
              // console.log(subscriptions)
              this.userSubscriptions = subscriptions.map(sub => {
                const plan = plans.find(p => p.id === sub.planId)
                // console.log('plan', plan)
                return { ...plan, ...sub, plan }
              })
              // console.log('userSubscriptions', this.userSubscriptions)
              SubscriptionPlanService.getRenewalInformation({
                userPlanIds: this.userSubscriptions.map(s => s.userPlanId),
              })
              .then(renewals => {
                this.userSubscriptions = this.userSubscriptions.map(sub => {
                  const renewal = renewals.find(r => r.userPlanId === sub.userPlanId)
                  // console.log('renewal', renewal)
                  return { ...sub, renewal, renewalStatus: renewal?.status ?? 1 }
                })
                // console.log('userSubscriptions', this.userSubscriptions)
              })
            },
            error => {
              console.log(error)
            },
          )
          .finally(() => EventBus.$emit('showLoading', false))
        },
        error => {
          console.log(error)
        },
      )
    },
    stopSubscriptionDataOpen (sub) {
      this.stopSubscriptionModalData.show = true
      this.stopSubscriptionModalData.msisdn = sub.route?.msisdn
      this.stopSubscriptionModalData.userPlanId = sub.userPlanId
      this.stopSubscriptionModalData.userSubscriptionId = sub.userSubscriptionId
      this.stopSubscriptionModalData.hasSubscription = sub.hasSubscription
      this.stopSubscriptionModalData.plan = sub.plan
      this.stopSubscriptionModalData.finalizedDate = sub.endDate
    },
    closeDialogStopSubscription () {
      this.stopSubscriptionModalData.show = false
      this.stopSubscriptionModalData.hasSubscription = false
    },
    actionsStopSubscription (userPlanId) {
      this.stopSubscriptionModalData.show = false
      const subscription = this.userSubscriptions.find(sub => sub.userPlanId === userPlanId)
      subscription.hasSubscription = false
      subscription.isSubscriptionCancelled = true
      this.$forceUpdate()
    },
    stopSubscriptionPlan (sub) {
      SubscriptionPlanService.stopSubscription({ userPlanId: sub.userPlanId })
      .then(result => {
        if (result.success) {
          sub.hasSubscription = false
        }
      })
    },
    startSubscriptionPlan (planType, isAdditional) {
      this.$router.push({ path: '/payments/' + planType + '-subscription-needed', query: { isAdditional: isAdditional } }).catch(() => { this.$forceUpdate() })
    },
    sendSupportRequest () {
      this.$router.push({ name: 'support' }).catch(() => { this.$forceUpdate() })
    },
    updateSubscriptionCardPlan (planType, userPlanId, userSubscriptionId) {
      this.$router.push({ name: 'PlanSubscriptionUpdateCardNeeded', params: { userPlanId, userSubscriptionId, planType } }).catch(() => { this.$forceUpdate() })
    },
    getPlanFee (planType) {
      return this.plans.find(p => p.planType === planType)?.planFee || 0
    },
    getPlanSetup (planType) {
      return this.plans.find(p => p.planType === planType)?.planSetup || 0
    },
    getPlanAdditionalFee (planType) {
      return this.plans.find(p => p.planType === planType)?.planAdditionalFee || 0
    },
    getPlanAdditionalSetup (planType) {
      return this.plans.find(p => p.planType === planType)?.planAdditionalSetup || 0
    },
    getStandardPlanFee (planType) {
      switch (this.user.currencyCode) {
        case 'EUR':
          return 99
        case 'COP':
          return 250000
        case 'USD':
          return 70
        case 'MXN':
          return 1000
        default:
          return 99
      }
    },
    getStandardPlanSetup (planType) {
      switch (this.user.currencyCode) {
        case 'EUR':
          return 199
        case 'COP':
          return 300000
        case 'USD':
          return 85
        case 'MXN':
          return 1500
        default:
          return 199
      }
    },
    hadPaymentProblem (sub) {
      return sub.renewalStatus === 0
    },
    retryPayment (sub, planType) {
      console.log(sub)
      this.$router.push({ name: 'RetrySubscriptionRenewPayment', params: { sub, planType } }).catch(() => { this.$forceUpdate() })
    },
  },
}
